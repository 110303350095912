import React from 'react';

import type { Promo as PromoType } from '../../../types/Deal';
import { VerifiedCheckmark } from '../VerifiedCheckmark/VerifiedCheckMark';

import styles from './styles/promo.css';

interface PromoProps {
  item: Partial<PromoType>;
  className?: string;
}

const Promo: React.FC<PromoProps> = ({ item, className }) => {
  const sanitizedClassName = className && styles[className] ? className : 'container';

  return (
    <div className={styles[sanitizedClassName]} data-type={item.type} title={item.value}>
      {item.type === 'verified' && <VerifiedCheckmark />}
      {item.display_value}
    </div>
  );
};

export default Promo;
