import React from 'react';

import { LabelPromo } from '@fie/analytics-library/src/types/LabelPromo';

import { loadCSS } from '../../../../utils/loadCSS';

import Promo from './Promo';
import styles from './styles/promos.css';

loadCSS('promos');

interface PromosProps {
  className: string;
  showLabels?: boolean;
  showOverlay?: boolean;
  children?: React.ReactNode;
  items: LabelPromo[];
}

const Promos: React.FC<PromosProps> = ({ className, children, items }) => {
  const containerStyle = styles[className] ? className : 'container';

  return (
    <div className={styles[containerStyle]}>
      <div className={styles.main}>
        {(items || []).map((item, index) => (
          <Promo
            {...{
              item,
              className,
              key: `${item.type}-${index}`,
            }}
          />
        ))}
        {children}
      </div>
    </div>
  );
};

export default Promos;
